import { useContext, useState } from "react";
import { Command, ServiceGroupType, Region, sapphire_network } from "./utils/interfaces";
import { Context } from "../context";

export interface ICommandProps {
  onClosePopUp: () => void;
}

export function CommandForm ({ onClosePopUp }: ICommandProps) {

  const ctx = useContext(Context);

  const [env, setEnv] = useState<sapphire_network>("dev");
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cmd, setCmd] = useState<Command | undefined>();
  const [region, setRegion] = useState<Region>("asia-southeast1");
  const [serviceGroupType, setServiceGroupType] = useState<ServiceGroupType>("dkgsecp256k1");
  const [serviceGroupId, setServiceGroupId] = useState<string>("");

  function commandSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setCmd(e.target.value as Command);
    setServiceGroupId("");
  }

  function regionSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setRegion(e.target.value as Region);
  }

  function envSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setEnv(e.target.value as sapphire_network);
  }

  function serviceGroupTypeSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setServiceGroupType(e.target.value as ServiceGroupType);
  }

  function serviceGroupIdOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setServiceGroupId(e.target.value);
  }

  function closePopUp() {
    setCmd(undefined);
    onClosePopUp();
  }

  function getConfirmMessage(): string {
    if (cmd === "uninstall") {
      return `You are about execute a command(${cmd} ${serviceGroupId}) in *${region}-${env}*. Proceed?`
    }
    return `You are about execute a command(${cmd} ${serviceGroupType}) in *${region}-${env}*. Proceed?`
  }

  async function onSubmit(e: React.FormEvent) {
    try {
      e.preventDefault();
      if (!cmd) {
        return;
      }
      const cfmSubmit = window.confirm(getConfirmMessage())
      if (cfmSubmit) {
        setLoading(true);
        const headers = new Headers();
        const cred = `${ctx.config.username}:${ctx.config.password}`;
        headers.set("Authorization", `Basic ${btoa(cred)}`);
        headers.set("Content-Type", "application/json");
        const response = await fetch(`/api/queue/enqueue`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            service_group_id: serviceGroupId,
            service_group_type: serviceGroupType,
            region,
            env,
            command: cmd,
          }),
        });
        if (response.status === 201) {
          setLoading(false);
          setError(undefined);
          setCmd(undefined);
          onClosePopUp();
          return;
        }
        throw new Error(response.statusText);
      }
    } catch (err: unknown) {
      setLoading(false);
      setError((err as Error).message);
    }
  }

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close" onClick={closePopUp}>
          Close
        </button>
        <div className="cmd-form">
          <form>
            <h3 className="title">Execute Command</h3>
            { error && <p className="error">{error}</p>}

            <label>Environment</label>
            <select name="Command" value={env} onChange={envSelectOnChange}>
              <option value="dev">Dev</option>
              <option value="prod">Production</option>
            </select>

            <label>Command</label>
            <select name="Command" value={cmd} onChange={commandSelectOnChange}>
              <option value={undefined}></option>
              <option value="install">install</option>
              <option value="install-standby">install-standby</option>
              <option value="install-on-standby-or-install">install-on-standby-or-install</option>
              <option value="uninstall">uninstall</option>
              <option value="create-backup">create-backup</option>
              <option value="clean-up-dkg-badger">clean-up-dkg-badger</option>
            </select>

            <label>Region</label>
            <select value={region} onChange={regionSelectOnChange}>
              <option value="asia-southeast1">Singapore</option>
              <option value="us-central1">US</option>
              <option value="europe-central2">EU</option>
              { env === "prod" &&  <option value="southamerica-east1">Brazil</option>}
            </select>

            {
              (cmd === "create-backup" || cmd === "install" || cmd === "install-standby" || cmd === "install-on-standby-or-install") && (
                <>
                  <label>Service Group Type</label>
                  <select value={serviceGroupType} onChange={serviceGroupTypeSelectOnChange}>
                    <option value="dkgsecp256k1">Secp256k1</option>
                    <option value="dkged25519">Ed25519</option>
                  </select>
                </>
              )
            }

            {
              (cmd === "uninstall" || cmd === "clean-up-dkg-badger") && (
                <>
                  <label>Service Group Id</label>
                  <input
                    placeholder="e.g dkgsecp256k1-991"
                    onChange={serviceGroupIdOnChange}
                    value={serviceGroupId}
                  />
                </>
              )
            }
            <button
              className="submit"
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Send command"}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
