import { useCallback, useContext, useEffect, useState } from "react";
import { Table } from "./common/Table";
import { determineDKGHealth, generateEndpoints } from "./utils/helpers";
import { Context } from "../context";
import { StatusBadge } from "./common/StatusBadge";
import { DKGStatus, INodeStatus, NodeStatusResponse, sapphire_network } from "./utils/interfaces";

interface INodeStatusProps {
  publicUrl: string;
  serviceGroupId: string;
  env: sapphire_network;
  onClosePopUp: () => void;
}

export function NodeStatusPage({ serviceGroupId, publicUrl, onClosePopUp, env }: INodeStatusProps) {
  const { config } = useContext(Context);

  const [statusList, setStatusList] = useState<INodeStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false); 
  // Removing error here results in a build error on setError(undefined), consider refactoring to remove this problem.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | undefined>(undefined);
  const [nodeHealth, setNodeHealth] = useState<DKGStatus>("unhealthy");

  const fetchStatus = useCallback(async function(signal?: AbortSignal){
    try {
      setLoading(true);
      const endpoints = generateEndpoints(publicUrl, env);
      const responses: INodeStatus[] = await Promise.all(endpoints.map(async (endpoint, idx) => {
        const apiUrl = `/api/proxy?url=${encodeURIComponent(endpoint)}`
        const headers = new Headers();
        const cred = `${config.username}:${config.password}`;
        headers.set("Authorization", `Basic ${btoa(cred)}`);
        headers.set("Content-Type", "application/json");
        const res = await fetch(apiUrl, {
          method: "POST",
          headers,
          body: JSON.stringify({
            jsonrpc: "2.0",
            method: "Status",
            params: {},
            id: "20"
          }),
          signal: signal
        });
        if (res.status === 200) {
          const json: NodeStatusResponse = await res.json()
          return {
            ...json.result,
            index: idx + 1,
            endpoint,
          };
        }
        return {
          index: idx + 1,
          endpoint,
          error: res.statusText,
          lastIndexCreated: -1,
        }
      }));

      const lastIndexCreateds = responses.map(r => r.lastIndexCreated || 0);
      setNodeHealth(determineDKGHealth(lastIndexCreateds));

      setStatusList(responses);
      setError(undefined);
      setLoading(false);
    } catch (err: unknown) {
      setError((err as Error).message);
      setLoading(false);
    }
  }, [config.password, config.username, env, publicUrl])

  useEffect(() => {
    const ac = new AbortController();
    (async function() {
      await fetchStatus(ac.signal);
    })()
    return () => {
      ac.abort();
    }
  }, [fetchStatus]);

  return (
    <div className="popup-status">
      <div className="popup-inner-status">
        <button className="close" onClick={onClosePopUp}>
          Close
        </button>
        <div className="table-container">
          <div className="header">
            <div className="header-status">
              <h4>{serviceGroupId}</h4>
              <StatusBadge status={nodeHealth}/>
            </div>
            <button onClick={() => fetchStatus()}>Refresh</button>
          </div>
          {
            loading ? <p>Loading...</p> : (
              <Table columns={["Node Index", "Last Created Index", "Status", "Error"]}>
                {
                  statusList.map((status, idx) => (
                    <tr key={idx}>
                      <td>{status.index}</td>
                      <td>{status.lastIndexCreated || 0}</td>
                      <td>{status.status || "error"}</td>
                      <td>{status.error || ""}</td>
                    </tr>
                  ))
                }
              </Table>
            )
          }
        </div>
      </div>
    </div>
  )
}