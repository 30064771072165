import { useCallback, useEffect, useState } from "react";
import { Table } from "./common/Table";
import { StatusBadge } from "./common/StatusBadge";
import { APIResponseWithPagination, ProcessTrackerResponse } from "./utils/interfaces";
import { parseDateString, parseErrorMessages } from "./utils/helpers";
import { DKG_AUTOSCALER_TASKS_COLUMNS } from "./utils/constants";


const LIMIT = 10;

export function AutoscalerTasks() {
  const [page, setPage] = useState<number>(1);
  const [error, setError] = useState<string | undefined>();
  const [autoScalerProcesses, setAutoScalerProcesses] = useState<APIResponseWithPagination<ProcessTrackerResponse> | undefined>();

  const getTasks = useCallback(async function() {
    try {
      const url = `/api/aggregator/tasks?page=${page}&limit=${LIMIT}`;
      const resp = await fetch(url, { method: "GET", headers: { "Content-Type": "application/json" } });
      if (resp.status === 200) {
        setError(undefined);
        const json = await resp.json();
        return json
      }
      throw new Error(parseErrorMessages(resp.status));
    } catch (err: unknown) {
      setError((err as Error).message);
    }
  }, [page]);

  function onPageChange(p: number) {
    const totalPages = autoScalerProcesses?.metadata.total_pages;
    if (!totalPages) {
      return;
    }

    if (p > 0 && p <= totalPages) {
      setPage(p);
    }
  }

  async function onRefresh() {
    const tasks = await getTasks();
    setAutoScalerProcesses(tasks)
  }

  useEffect(() => {
    (async function() {
      const tasks = await getTasks();
      setAutoScalerProcesses(tasks);
    })()
  }, [getTasks])

  return (
    <div>
      <button onClick={onRefresh}>Refresh</button>
      {
        autoScalerProcesses
          ? (
            <div>
              <Table columns={DKG_AUTOSCALER_TASKS_COLUMNS}>
              {
                autoScalerProcesses?.data?.map((p, idx) => (
                  <tr key={idx}>
                    <td>{`${p.event}${p.action && p.action !== "" ? `(${p.action})` : ""}`}</td>
                    <td>{p.region}</td>
                    <td>{p.id}</td>
                    <td>{p.env}</td>
                    <td><StatusBadge status={p.State} /></td>
                    <td>{parseDateString(p.CreatedAt)}</td>
                    <td>{parseDateString(p.UpdatedAt)}</td>
                  </tr>
                ))
              }
            </Table>
            <div className="pagination">
              <button onClick={() => onPageChange(page - 1)} disabled={page < 2}>Prev</button>
              <button onClick={() => onPageChange(page + 1)} disabled={page >= autoScalerProcesses?.metadata.total_pages}>Next</button>
            </div>
          </div>
          ) : (
            <p className="error">{error}</p>
          )
      }
    </div>
  )
}