import { useContext, useState } from "react";
import { Context } from "../context";
import { IAuthResponse, IConfig } from "./utils/interfaces";

export interface IConfigProps {
  onClosePopUp: () => void;
}

export function ConfigForm({ onClosePopUp }: IConfigProps) {
  const { config, setConfig } = useContext(Context);

  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();
  const [cfg, setCfg] = useState<IConfig>({username: config.username, password: config.password});

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCfg({
      ...cfg,
      [e.target.name]: e.target.value
    })
  }

  async function onSave(e: React.FormEvent) {
    e.preventDefault();
    try {
      const { username, password } = cfg;
      const resp = await fetch("/api/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username, password
        })
      });
      if (resp.status !== 200) {
        const json: IAuthResponse = await resp.json();
        throw new Error(json.error || "unexpected error!")
      }
      setError(undefined);
      setSuccess("Save config successfully")
    } catch (err: unknown) {
      setError((err as Error).message);
      setSuccess(undefined);
    } finally {
      setConfig(cfg);
    }
  }

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close" onClick={onClosePopUp}>
          Close
        </button>
        <div className="cmd-form">
          <form>
            <h3 className="title">API Credentials</h3>
            <label>Username</label>
            <input
              placeholder="username"
              onChange={handleOnChange}
              value={cfg.username}
              name="username"
            />
            <label>Password</label>
            <input
              placeholder="password"
              onChange={handleOnChange}
              value={cfg.password}
              name="password"
              type="password"
            />
            <button
              className="save"
              onClick={onSave}
            >
              Save
            </button>
            { error && <p className="error">{error}</p> }
            { success && <p className="success">{success}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}