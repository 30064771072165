import { createContext } from "react";
import { IConfig } from "./components/utils/interfaces";

const defaultConfig: IConfig = {
  username: "",
  password: "",
};

export type GlobalConfig = {
  config: IConfig,
  setConfig: (c: IConfig) => void
}

export const Context = createContext({
  config: defaultConfig, setConfig: (c: IConfig) => { },
});