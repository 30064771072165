import "./table.css";

export interface ITableProps {
  columns: string[];
  children: React.ReactNode;
}

export function Table({
  columns,
  children,
}: ITableProps) {
  return (
    <table className="table">
      <thead>
        <tr>
          {
            columns.map((c, idx) => <th key={idx}>{c}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

