import "./processStatus.css";
import { useState } from "react";
import { AutoscalerTasks } from "./AutoscalerTasks";
import { CoordinatorTasks } from "./CoordinatorTasks";

type Server = "coordinator" | "dkg-autoscaler"

export function ProcessStatus() {
  const [server, setServer] = useState<Server>("dkg-autoscaler")


  function serverSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setServer(e.target.value as Server);
  }

  return (
    <div>
      <div className="ps-opts">
        <label>Server: </label>
        <select value={server} onChange={serverSelectOnChange}>
          <option value="coordinator">Coordinator</option>
          <option value="dkg-autoscaler">DKG-Autoscaler</option>
        </select>
      </div>
      {
        server === "dkg-autoscaler"
          ? (
            <AutoscalerTasks />
          ) : (
            <CoordinatorTasks />
          )
      }
    </div>
  )
}