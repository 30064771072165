import { DKGStatus } from "./interfaces";

export function parseDateString(dateString: string): string {
  return new Date(dateString).toLocaleString();
}

export function parseErrorMessages(statusCode: number): string {
  switch (statusCode) {
    case 403:
      return "Permission denied!";
    case 500:
      return "Internal server error!";
    default:
      return "Unknown error!"
  }
}

export function generateEndpoints(url: string, env?: string): string[] {
  const endpoints: string[] = [];
  for (let i = 1; i < 6; i++) {
    let re = /dev-[0-9]-[0-9]/;
    let toReplace = `dev-2-${i}`;
    if (env === "prod") {
      re = /sapphire-[1-6]/;
      toReplace = `sapphire-${i}`;
    }
    const newUrl = url.replace(re, toReplace);
    endpoints.push(`${newUrl}/jrpc`)
  }
  return endpoints;
}

export function determineDKGHealth(lastIdxesCrated: number[]): DKGStatus {
  const max = Math.max(...lastIdxesCrated);
  const min = Math.min(...lastIdxesCrated);

  if (max < 0 || min < 0) {
    return "unhealthy";
  }

  if ((max - min) > 10) {
    return "unhealthy";
  }

  return "healthy";
}
