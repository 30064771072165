import { useState } from 'react';
import './App.css';
import { Body } from './components/Body';
import { Header } from './components/Header';
import { IConfig } from './components/utils/interfaces';
import { Context } from './context';

function App() {
  const defaultConfig: IConfig = { username: "", password: ""};
  const [config, setConfig] = useState<IConfig>(defaultConfig);
  return (
    <div className="App">
      <Context.Provider value={{config, setConfig}}>
        <Header />
        <Body />
      </Context.Provider>
    </div>
  );
}

export default App;
