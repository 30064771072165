import { useState } from "react";
import "./body.css";
import { ProcessStatus } from "./ProcessStatus";
import { Nodelist } from "./NodeList";

type header = "nodelist" | "process";

export function Body() {

  const [ header, setHeader ] = useState<header>("nodelist");

  const onHeaderClick = (h: header) => setHeader(h);

  return (
    <div>
      {/** Header */ }
      <div className="body-header">
        <div
          onClick={() => onHeaderClick("nodelist")}
          className={`left ${header === "nodelist" ? "active" : ""}`}>DKG Nodelist</div>
        <div
          onClick={() => onHeaderClick("process")}
          className={`left ${header === "process" ? "active" : ""}`}>Process Status</div>
      </div>
      {/** Content */}
      <div className="content-container">
        {
          header === "nodelist" ? <Nodelist /> : <ProcessStatus/>
        }
      </div>
    </div>
  )
}