import { useState, useCallback, useEffect } from "react";
import { Table } from "./common/Table";
import { StatusBadge } from "./common/StatusBadge";
import { APIResponseWithPagination, ICoordinatorProcessStatus, sapphire_network } from "./utils/interfaces";
import { parseDateString, parseErrorMessages } from "./utils/helpers";
import { COORDINATOR_JOB_COLUMNS } from "./utils/constants";


const LIMIT = 10;

export function CoordinatorTasks() {
  const [env, setEnv] = useState<sapphire_network>("dev");
  const [coordinatorIdx, setCoordinatorIdx] = useState<number>(1);
  const [coordinatorProcesses, setCoordinatorProcesses] = useState<APIResponseWithPagination<ICoordinatorProcessStatus> | undefined>();
  const [page, setPage] = useState<number>(1);
  const [error, setError] = useState<string | undefined>();

  const getTasks = useCallback(async function(){
    try {
      const url = `/api/dashboard/coordinator-requests?nodeIdx=${coordinatorIdx}&page=${page}&limit=${LIMIT}&env=${env}`;
      const resp = await fetch(url, { method: "GET", headers: { "Content-Type": "application/json" } });
      if (resp.status === 200) {
        setError(undefined)
        const json = await resp.json();
        return json;
      }
      throw new Error(parseErrorMessages(resp.status));
    } catch (err: unknown) {
      setError((err as Error).message);
    }
  }, [page, env, coordinatorIdx]);

  function onPageChange(p: number) {
    if (coordinatorProcesses?.metadata.total_pages && coordinatorProcesses.metadata.total_pages >= p) {
      setPage(p);
    }
  }

  function envSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setEnv(e.target.value as sapphire_network);
  }

  function coordinatorIdxOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setCoordinatorIdx(parseInt(e.target.value));
  }

  async function onRefresh() {
    const t = await getTasks();
    setCoordinatorProcesses(t);
  }

  useEffect(() => {
    (async function() {
      const t = await getTasks();
      setCoordinatorProcesses(t);
    })()
  }, [getTasks]);

  return (
    <div>
      <div className="options">
        <div className="filter">
          <select value={env} onChange={envSelectOnChange}>
            <option value="dev">Dev</option>
            <option value="prod">Prod</option>
          </select>
          <select value={coordinatorIdx} onChange={coordinatorIdxOnChange}>
            <option value="1">Coordinator-1</option>
            <option value="2">Coordinator-2</option>
            <option value="3">Coordinator-3</option>
            <option value="4">Coordinator-4</option>
            <option value="5">Coordinator-5</option>
          </select>
        </div>
        <button onClick={onRefresh}>Refresh</button>
      </div>
      {
        coordinatorProcesses
          ? (
            <div>
              <Table columns={COORDINATOR_JOB_COLUMNS}>
                {
                  coordinatorProcesses?.data.map((p, idx) => (
                    <tr key={idx}>
                      <td>{p.requestId}</td>
                      <td>{p.details.Region}</td>
                      <td>{p.details.ServiceType}</td>
                      <td>{p.details.ServiceId}</td>
                      <td>{p.details.ServiceAction}</td>
                      <td><StatusBadge status={p.details.Status} /></td>
                      <td>{p.details.Error}</td>
                      <td>{parseDateString(p.details.LastUpdatedAt)}</td>
                      <td>{parseDateString(p.details.StartedAt)}</td>
                    </tr>
                  ))
                }
              </Table>
              <div className="pagination">
                <button onClick={() => onPageChange(page - 1)} disabled={page < 2}>Prev</button>
                <button onClick={() => onPageChange(page + 1)} disabled={page >= coordinatorProcesses?.metadata.total_pages}>Next</button>
              </div>
            </div>
          ) : (
            <p className="error">{error}</p>
          )
      }
    </div>
  )
}