import { useCallback, useEffect, useState } from "react";
import "./nodelist.css";
import { Table } from "./common/Table";
import { parseErrorMessages } from "./utils/helpers";
import { DkgJrpcMethods } from "./DkgJrpcMethods";
import { IDKGNode, IDKGNodeList, IDKGNodeResponse, NodeStatus, Region, ServiceGroupType, sapphire_network } from "./utils/interfaces";
import { NodeStatusPage } from "./NodeStatusPage";
import { StatusBadge } from "./common/StatusBadge";
import { DKG_LIST_COLUMNS } from "./utils/constants";


export function Nodelist() {

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [nodeList, setNodeList] = useState<IDKGNode[]>([]);
  const [env, setEnv] = useState<sapphire_network>("dev");
  const [region, setRegion] = useState<Region>("asia-southeast1");
  const [nodeStatus, setNodeStatus] = useState<NodeStatus>("active");
  const [serviceType, setServiceType] = useState<ServiceGroupType>("dkgsecp256k1");
  const [showJrpcMethodPopup, setShowJrpcMethodPopup] = useState<boolean>(false);
  const [showStatusPage, setShowStatusPage] = useState<boolean>(false);
  const [selectedServiceGroupId, setSelectedServiceGroupId] = useState<string>("");
  const [dkgPublicUrl, setDkgPublicUrl] = useState<string>("");

  function envSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setEnv(e.target.value as sapphire_network);
  }

  function regionSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setRegion(e.target.value as Region);
  }

  function nodeStatusSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setNodeStatus(e.target.value as NodeStatus);
  }

  function serviceTypeSelectOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setServiceType(e.target.value as ServiceGroupType);
  }

  const fetchNodeList = useCallback(async function(signal?: AbortSignal): Promise<IDKGNodeList> {
    try {
      setLoading(true);
      const resp = await fetch(`/api/dashboard/nodelist?region=${region}&status=${nodeStatus}&type=${serviceType}&env=${env}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        signal,
      });
      if (resp.status === 200) {
        const json = await resp.json();
        setError(undefined);
        setLoading(false);
        return json.map((j: IDKGNodeResponse) => {
          const { service_group_id, fqdn, remove_at_block, is_pause, public_url, status, states } = j;
          const nodeStatus = is_pause ? "backup" : (remove_at_block === 0 ? "online" : "offline");
          return { service_group_id, fqdn, status: nodeStatus, public_url, health: status, states };
        });
      }
      throw new Error(parseErrorMessages(resp.status));
    } catch (err: unknown) {
      setLoading(false);
      setError((err as Error).message);
      return [];
    }
  }, [region, nodeStatus, serviceType, env]);

  async function onRefresh() {
    const nl = await fetchNodeList();
    setNodeList(nl);
  }

  function handleKeyAssignOnClick(url: string) {
    setDkgPublicUrl(`${url}/jrpc`);
    setShowJrpcMethodPopup(true);
  }

  function handleCheckStatusOnClick(url: string, serviceGroupId: string) {
    setDkgPublicUrl(url);
    setShowStatusPage(true);
    setSelectedServiceGroupId(serviceGroupId);
  }

  useEffect(() => {
    const ac = new AbortController();
    (async function() {
      const nl = await fetchNodeList(ac.signal);
      setNodeList(nl);
    })()
    return () => {
      ac.abort();
    }
  }, [fetchNodeList]);

  return (
    <div>
      {
        showJrpcMethodPopup && <DkgJrpcMethods onClosePopUp={() => setShowJrpcMethodPopup(false)} url={dkgPublicUrl} env={env}/>
      }
      {
        showStatusPage && <NodeStatusPage env={env} onClosePopUp={() => setShowStatusPage(false)} publicUrl={dkgPublicUrl} serviceGroupId={selectedServiceGroupId}/>
      }
      <div className="options">
        <div className="filter">
          <select value={env} onChange={envSelectOnChange}>
            <option value="dev">Dev</option>
            <option value="prod">Prod</option>
          </select>
          <select value={region} onChange={regionSelectOnChange}>
            <option value="asia-southeast1">Singapore</option>
            <option value="us-central1">US</option>
            <option value="europe-central2">EU</option>
            { env === "prod" && <option value="southamerica-east1">Brazil</option> }
          </select>
          <select value={serviceType} onChange={serviceTypeSelectOnChange}>
            <option value="dkgsecp256k1">Secp256k1</option>
            <option value="dkged25519">Ed25519</option>
          </select>
          <select value={nodeStatus} onChange={nodeStatusSelectOnChange}>
            <option value="active">Active</option>
            <option value="all">All</option>
          </select>
        </div>
        <button onClick={onRefresh}>
          Refresh
        </button>
      </div>
      { error && <p className="error">{error}</p> }
      {
        loading ? <p>Loading ...</p> : (
          <Table columns={DKG_LIST_COLUMNS}>
            {
              nodeList?.map((node, idx) => (
                <tr key={idx}>
                  <td><a href={`${node.public_url}/jrpc`}>{node.service_group_id}</a></td>
                  <td>
                  <StatusBadge status={node.status} />
                  </td>
                  <td>
                    <div>
                      <button onClick={() => handleKeyAssignOnClick(node.public_url)}>Test methods</button>
                      <button onClick={() => handleCheckStatusOnClick(node.public_url, node.service_group_id)}>Check status</button>
                    </div>
                  </td>
                </tr>
              ))
            }
          </Table>
        )
      }
    </div>
  )
}