import { useContext, useState } from "react";
import { Context } from "../context";
import { parseErrorMessages } from "./utils/helpers";
import { sapphire_network } from "./utils/interfaces";

interface IDkgJrpcMethodsProps {
  onClosePopUp: () => void;
  url: string;
  env: sapphire_network;
}

export function DkgJrpcMethods({ onClosePopUp, url, env }: IDkgJrpcMethodsProps) {
  const { config } = useContext(Context);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [method, setMethod] = useState<string>("KeyAssign");
  const [verifier, setVerifier] = useState<string>();
  const [verifierId, setVerifierId] = useState<string>();
  const [nodeIndex, setNodeIndex] = useState<number>(1);
  const [result, setResult] = useState<string>();
  const [jrpcUrl, setJrpcUrl] = useState<string>(url);

  function handleInputOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "verifier") {
      setVerifier(e.target.value);
    } else {
      setVerifierId(e.target.value);
    }
  }

  function onNodeIndexChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setNodeIndex(parseInt(e.target.value));
    if (env === "prod") {
      const re = /sapphire-[1-5]/;
      const newUrl = url.replace(re, `sapphire-${e.target.value}`);
      setJrpcUrl(newUrl);
    } else {
      const re = /dev-[0-9]-[0-9]/;
      const newUrl = url.replace(re, `dev-2-${e.target.value}`);
      setJrpcUrl(newUrl);
    }
  }
  
  function closePopUp() {
    onClosePopUp();
  }

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      const apiUrl = `/api/proxy?url=${encodeURIComponent(jrpcUrl)}`
      const headers = new Headers();
      const cred = `${config.username}:${config.password}`;
      headers.set("Authorization", `Basic ${btoa(cred)}`);
      headers.set("Content-Type", "application/json");
      const resp = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          jsonrpc: "2.0",
          method,
          params: {
            verifier, verifier_id: verifierId, nonce: "0",
          },
          id: "20"
        }),
      });

      if (resp.status === 200) {
        setError(undefined);
        const json = await resp.json();
        setResult(JSON.stringify(json));
        return;
      }
      throw new Error(parseErrorMessages(resp.status));
    } catch (err: unknown) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close" onClick={closePopUp}>
          Close
        </button>
        <div className="cmd-form">
          <form>
            <h3 className="title">Send Method</h3>
            <label>Node Index</label>
            <select name="NodeIndex" value={nodeIndex} onChange={onNodeIndexChange}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <label>URL</label>
            <input
              placeholder=""
              value={jrpcUrl}
              disabled
            />
            <label>Method Name</label>
            <select name="MethodName" value={method} onChange={(e) => setMethod(e.target.value)}>
              <option value="KeyAssign">KeyAssign</option>
              <option value="Status">Status</option>
            </select>
            {
              method === "KeyAssign" && (
                <>
                  <label>Verfier</label>
                    <input
                      placeholder="test@email.com"
                      onChange={handleInputOnChange}
                      value={verifier}
                      name="verifier"
                    />
                    { error && <p className="error">{error}</p>}
                    <label>Verfier ID</label>
                    <input
                      placeholder=""
                      onChange={handleInputOnChange}
                      value={verifierId}
                      name="verifierId"
                    />
                </>
              )
            }
            <button
              className="submit"
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Send command"}
            </button>
          </form>
          {
            result !== "" && (
              <div className="result">{result}</div>
            )
          }
        </div>
      </div>
    </div>
  )
}