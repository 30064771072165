import { useState } from "react";
import "./header.css";
import { CommandForm } from "./Command";
import { ConfigForm } from "./Config";

export function Header() {

  const [showCommandForm, setShowCommandForm] = useState<boolean>(false);
  const [showConfigForm, setShowConfigForm] = useState<boolean>(false);
  

  return (
    <div className="nav">
      <h3>DKG Autoscaler</h3>
      <div>
        <button onClick={() => setShowCommandForm(true)}>Post Command</button>
        <button onClick={() => setShowConfigForm(true)}>Config</button>
      </div>
      {
        showCommandForm && <CommandForm onClosePopUp={() => setShowCommandForm(false)}/>
      }
      {
        showConfigForm && <ConfigForm onClosePopUp={() => setShowConfigForm(false)} />
      }
    </div>
  )
}